import React, { useContext, useEffect, useRef, useState } from 'react'
import "./assets/css/app.css"
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import axiosClient from './axios-client';
import Cookies from 'js-cookie';

export default function SignIn() {

    const navigate = useNavigate();

    const {dispatch} = useContext(AuthContext);
  
    const inputs = useRef([]);
    const [validationerror , setValidationError] = useState("");
    const [loadingsubmitbutton, setLoadingSubmitButton] = useState(false);
    const [errorauthentification,setErrorAuthentification] = useState("");
  
    const [rememberMe, setRememberMe] = useState(false);
    const [user, setUser] = useState({ emailOrNumber : "", password : "" });
  
    const formRef = useRef();
  
    useEffect(() => {
      const rememberMeCookie = Cookies.get('rememberMe');
      if (rememberMeCookie) {
        setRememberMe(true);
        const userData = JSON.parse(rememberMeCookie);
        setUser(userData);
      }else{
        const userData = { emailOrNumber : "", password : "" };
        setUser(userData);
      }
    }, []);
  
    const addInputs = el => {
      if (el && !inputs.current.includes(el)) {
        inputs.current.push(el)
      }
    }
  
    const handleSignIn = async (event) =>{
      event.preventDefault();
      const errors = {};
  
      if (inputs.current[0].value.trim() === '') {
        errors.emailOrNumber = 'Email Address or Phone Number is required';
      }
      
      if (inputs.current[1].value.trim() === '') {
        errors.password = 'Password is required';
      }
  
      if (Object.keys(errors).length === 0) {
        setLoadingSubmitButton(true);
        const datauser = {
          email_or_number : inputs.current[0].value.trim(),
          password : inputs.current[1].value.trim()
        }
        await axiosClient.post('/login',datauser).then(({data})  => {
          const { user, token, resourceuser } = data;
          localStorage.setItem('profile_user_img', resourceuser.user_img);
          localStorage.setItem('profile_user_name', resourceuser.user_name);
          if (rememberMe) {
            const userData = { emailOrNumber : inputs.current[0].value.trim(), password : inputs.current[1].value.trim() };
            Cookies.set('rememberMe', JSON.stringify(userData), { expires: 365 });
            setUser(userData);
          } else {
            Cookies.remove('rememberMe');
          }
  
          dispatch({ type: "LOGIN", payload: { user, token } });
          navigate('/dashboard'); 
        }).catch(err => {
          const response = err.response;
          if(err.code === "auth/network-request-failed"){
            errors.checkingnetwork = 'Connexion internet requise';
            setValidationError(errors);
          }
          if (response && response.status === 422) {
            if (response.data.errors) {
              if (response.data.errors.email) {
                errors.email = response.data.errors.email;
                setValidationError(errors);
              }
            }else {
              errors.queryCheckuser_Email_Password = response.data.message;
              setErrorAuthentification(errors);
              setValidationError(errors);
            }
          }
          if (response && response.status === 500) {
            if (response.data.message) {
              errors.queryCheckuser_Email_Password = response.data.message;
              setErrorAuthentification(errors);
              setValidationError(errors);              
            }            
            if (response.data.state) {
              if (response.data.state == 'waiting_for') {
                errors.queryCheckuser_Email_Password_activ = 'votre compte est en attente d\'activation';
                setErrorAuthentification(errors);
              }
              if (response.data.state == 'idle') {
                errors.queryCheckuser_State = 'Votre compte a été desactivé';
                setErrorAuthentification(errors);
              }
            }
          }
        });
        setLoadingSubmitButton(false);
      }else{
        setValidationError(errors);
      }
    }

  return (
    <main class="d-flex w-100">
    <div class="container d-flex flex-column">
        <div class="row vh-100">
            <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                <div class="d-table-cell align-middle">

                    <div class="text-center mt-4">
                        <h1 class="h2">Welcome back To Administrator Esquire !</h1>
                        <p class="lead">
                            Sign in to your account to continue
                        </p>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="m-sm-3">
                                <form ref={formRef}>
                                    {errorauthentification.queryCheckuser_Email_Password ? <div className="alert alert-danger">
                                        <>{ errorauthentification.queryCheckuser_Email_Password }</>
                                    </div> : null}
                                    {errorauthentification.queryCheckuser_State ? <div className="alert alert-danger">{errorauthentification.queryCheckuser_State}</div> : null}
                                    {errorauthentification.queryCheckuser_Email_Password_activ ? <div className="alert alert-warning">
                                        { errorauthentification.queryCheckuser_Email_Password_activ }
                                    </div> : null}
                                    {validationerror.checkingnetwork ? <div class="alert alert-warning alert-dismissible fade show" role="alert">
                                        <>{ validationerror.checkingnetwork }</>
                                    </div>: null}
                                    <div class="mb-3">
                                        <label class="form-label">Email Address or Phone Number</label>
                                        <input class="form-control form-control-lg" ref={addInputs} defaultValue={ user.emailOrNumber ? user.emailOrNumber : "" } type="text" placeholder="" />
                                        {validationerror.emailOrNumber && <span className="text-danger">{validationerror.emailOrNumber}</span>}
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">Password</label>
                                        <input class="form-control form-control-lg" ref={addInputs} defaultValue={ user.password ? user.password : ""} type="password" placeholder="" />
                                        {validationerror.password && <span className="text-danger">{validationerror.password}</span>}
                                    </div>
                                    <div>
                                        <div class="form-check align-items-center">
                                            <label class="form-check-label text-small" for="customControlInline">
                                            <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id='customControlInline' class="form-check-input"/> Remember me</label>
                                        </div>
                                    </div>
                                    <div class="d-grid gap-2 mt-3">
                                        <button onClick={handleSignIn} class="btn btn-lg btn-primary">
                                            {loadingsubmitbutton ? <i class="fa fa-refresh fa-spin"></i> : null} Sign in
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mb-3">
                        did you forget your password? <Link to='/forget-password'>Click here</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
  )
}
